<template>
  <div id="dashboard-patrol">
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>
          Filters
        </h5>
      </b-card-header>
      <b-card-body>
        <validation-observer ref="filterRules">
          <b-row>
            <b-col
              cols="12"
              md="4"
              class="mb-md-0 mb-2"
            >
              <label>Site</label>
              <!-- <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :value="planFilter"
                :options="planOptions"
                class="w-100"
                :reduce="val => val.value"
                @input="(val) => $emit('update:planFilter', val)"
              /> -->
              <v-select
                v-model="filterData.areaFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="areaOptions"
                :reduce="val => val.value"
                :clearable="false"
              />
            </b-col>
            <b-col
              cols="12"
              md="4"
              class="mb-md-0 mb-2"
            >
              <label>From</label>
              <!-- <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :value="roleFilter"
                :options="roleOptions"
                class="w-100"
                :reduce="val => val.value"
                @input="(val) => $emit('update:roleFilter', val)"
              /> -->
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="From"
              >
                <flat-pickr
                  v-model="filterData.from"
                  class="form-control"
                  placeholder="YYYY-MM-DD"
                /> 
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="4"
              class="mb-md-0 mb-2 "
            >
              <label>To</label>
              <!-- <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :value="roleFilter"
                :options="roleOptions"
                class="w-100"
                :reduce="val => val.value"
                @input="(val) => $emit('update:roleFilter', val)"
              /> -->
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="to"
              >
                <flat-pickr
                  v-model="filterData.to"
                  class="form-control"
                  placeholder="YYYY-MM-DD"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="12"
              class="d-flex align-items-center justify-content-end mb-2 mb-md-2 mt-2"
            >
              <b-button
                variant="primary"
                @click="searchData"
              >
                <span class="text-nowrap">Filter</span>
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </b-card-body>
    </b-card>
    <!-- Table Container Card -->
    <b-card
      no-body
      v-if="isReportDataFetched"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Export Excel -->
          <b-col
            cols="12"
            md="12"
            class="d-flex align-items-center justify-content-end mb-2 mb-md-2"
          >
            <b-button
              variant="primary"
              @click="fetchDataExcel"
            >
                <span class="text-nowrap">Export Excel</span>
            </b-button>
          </b-col>
          <!-- Per Page -->
          <b-col
            cols="6"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Entries</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
          <!-- Search -->
          <b-col
            cols="6"
            md="6"
            class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
          >
            <div>
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refPatrolDetailReportsTable"
        :items="fetchPatrolDetailReports"
        :filter="searchQuery"
        responsive
        :fields="tableColumns"
        striped
        show-empty
        empty-text="No matching records found"
        class="position-relative"
        :filter-included-fields="['area_name','location_name', 'guard_name']"
      >
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalPatrolReport"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, 
  BRow, 
  BCol, 
  BFormInput, 
  BTable, 
  BPagination, 
  BButton,
  BCardHeader, 
  BCardBody,
} from 'bootstrap-vue'
import patrolDetailReportStoreModule from '@/views/apps/report/patrolDetailReportStoreModule'

import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { onUnmounted, ref, watch, computed, } from '@vue/composition-api'
import store from '@/store'
import { saveAs } from 'file-saver'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
//vee validate
import { ValidationProvider, ValidationObserver } from 'vee-validate'
//rules vee validate
import { required } from '@validations'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    BButton,
    BCardHeader,
    BCardBody,
   
    flatPickr,
    vSelect,

    ValidationProvider,
    ValidationObserver,
    required,
  },
  methods: {
    searchData(){
      this.$refs.filterRules.validate().then(success => {
        if (success) {
          this.fetchPatrolReports()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Search Success',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        }
      })
    },
    fetchDataExcel(){
      this.fetchExportExcelData()
    },
  },
  data() {
    return {
      // tanggalFilter:null,
      // isReportDataFetched:false,
    }
  },
  mounted(){
    this.getAreaOptions()
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-patrol-detail-report'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, patrolDetailReportStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const areaOptions = ref([])
    const filterData = ref({
      areaFilter: '',
      from: '',
      to: '',
    })
    const isReportDataFetched = ref(false)

    // Use toast
    const toast = useToast()

    const refPatrolDetailReportsTable = ref(null)
    const fetchPatrolDetailReports = ref(null)

    const perPage = ref(10)
    const totalPatrolReport = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)

    const dataMeta = computed(() => {
      const localItemsCount = refPatrolDetailReportsTable.value ? refPatrolDetailReportsTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalPatrolReport.value,
      }
    })

    const refetchData = () => {
      fetchPatrolReports()
      refPatrolDetailReportsTable.value.refresh()
    }

    watch([currentPage, perPage], () => {
      refetchData()
    })

    const fetchPatrolReports = (ctx, callback) => {
      store
        .dispatch('app-patrol-detail-report/fetchPatrolDetailReports',{
          from: filterData.value.from,
          to: filterData.value.to,
          area_id: filterData.value.areaFilter,
          perPage: perPage.value,
          currentPage: currentPage.value,
        })
        .then(response => {
          console.log('ini app rea')
          console.log(response.data.data)
          console.log(response)
          isReportDataFetched.value = true
          fetchPatrolDetailReports.value = response.data.data.list
          totalPatrolReport.value = response.data.data.total
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error Exporting Excel Patrol Report',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const getAreaOptions = () => {
      store
        .dispatch('app-patrol-detail-report/fetchArea')
        .then(response => {
          console.log('ini app rea')
          console.log(response.data.data)
          // roleOptions = response.data.data
          areaOptions.value = response.data.data
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching area list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const fetchExportExcelData = () => {
      store
        .dispatch('app-patrol-detail-report/fetchPatrolDetailReports',{
          from: filterData.value.from,
          to: filterData.value.to,
          area_id: filterData.value.areaFilter,
        })
        .then(response => {
          console.log('ini app rea')
          console.log(response.data.data)
          console.log(response)
          exportExcel(response.data.data.list)
        })
        .catch((e) => {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching Patrol Detail Reports list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const exportExcel = (data) => {
      console.log('ini data')
      console.log(data)
      const Excel = require('exceljs');

      const getCellByName = (worksheet, name) => {
        let match
        worksheet.eachRow(row => row.eachCell(cell => {
          if (cell.names.find(n => n === name)) {
            match = cell
          }
        }))
        return match
      }
      // Create workbook & add worksheet
      const workbook = new Excel.Workbook();
      const worksheet = workbook.addWorksheet('Sheet1');
      
      
      // add column headers
      worksheet.columns = [
        { header: 'Site', key: 'area_name' },
        { header: 'Checkpoint Name', key: 'location_name' },
        { header: 'Guard', key: 'guard_name' },
        { header: 'Date', key: 'tanggal' },
        { header: 'Time', key: 'jam' },
        { header: 'Type', key: 'type' },
        { header: 'Photos', key: 'image' },
      ];

      let counterFoto = 2
      let counterEmptyRow =0
      data.forEach((element, index) => {
        console.log(element)
        console.log('ini element')
        const rows = 
        { 
          area_name: element.area_name, location_name: element.location_name, 
          guard_name: element.guard_name, tanggal: element.tanggal ,
          jam: element.jam, type: typeConvert(element.type)
        }
        const emptyRow = ['', '', '', '', '' , '', '']
        if('foto' in element.data){
          console.log('ini foto')
          console.log(element.data.foto)
          let arrFotoSrc = element.data.foto
          if(arrFotoSrc.length > 1){
            arrFotoSrc.forEach((element) => {
              const imageId1 = workbook.addImage({
                base64: element,
                extension: 'jpeg',
              });
              worksheet.addImage(imageId1, `G${counterFoto}:G${counterFoto}`);
              counterFoto += 1
              counterEmptyRow += 1
            })
            for(let i=0; i < counterEmptyRow; i++){
              worksheet.addRow(emptyRow)
            }
            counterEmptyRow = 0
          }else {
            const imageId1 = workbook.addImage({
              base64: arrFotoSrc[0],
              // buffer: arrFotoSrc[0],
              extension: 'jpeg',
            });
            worksheet.addImage(imageId1, `G${counterFoto}:G${counterFoto}`);
            counterFoto += 1
            worksheet.addRow(emptyRow)
          }
        }else{
          rows.image = ''
          counterFoto += 1
          worksheet.addRow(rows)
        }
      });
      
      
      console.log(worksheet)
      console.log('iniworksheet')

      // save workbook to disk
      workbook
        .xlsx
        .writeBuffer()
        .then((file) => {
          saveAs(new Blob([file]), 'abc.xlsx')
        })
        .catch((err) => {
          console.log("err", err);
        });
    }

    const tableColumns = [
      { key: 'area_name', label: 'Site', sortable: true },
      { key: 'location_name', label: 'Checkpoint name', sortable: true },
      { key: 'guard_name', label: 'guard', sortable: true },
      { key: 'tanggal', label: 'date', sortable: true },
      { key: 'jam', label: 'time', sortable: true },
      { 
        key: 'type', 
        label: 'type', 
        sortable: true,
        formatter: (value) => {
          return typeConvert(value)
        }
      },
    ]

    const typeConvert = (value) => {
      if(value == 0){
        return 'Checking'
      } else if(value == 1){
        return 'Scan QR'
      } else if(value == 2){
        return 'Report'
      } else if(value == 3){
        return 'Finish Checkpoint'
      } else if(value == 4){
        return 'End Patrol'
      } else if(value == 99){
        return 'SOS'
      }
    }
    const getBase64Image = (imgUrl, callback) => {
      var img = new Image();

      // onload fires when the image is fully loadded, and has width and height

      img.onload = function(){

        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png"),
            dataURL = dataURL.replace(/^data:image\/(png|jpg);base64,/, "");

        callback(dataURL); // the base64 string

      };

      // set attributes and src 
      img.setAttribute('crossOrigin', 'anonymous'); //
      img.src = imgUrl;
    }

    return {
      fetchPatrolDetailReports,
      totalPatrolReport,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPatrolDetailReportsTable,
      toast,

      refetchData,
      
      areaOptions,
      isReportDataFetched,
      filterData,
      getAreaOptions,
      fetchPatrolReports,
      fetchExportExcelData,
      exportExcel,

      getBase64Image,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
