import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPatrolDetailReports(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${this.state.customConfig.baseURL}/web/report`, {
            apikey: this.state.customConfig.token,
            perPage: payload.perPage,
            currentPage: payload.currentPage,
            from: payload.from,
            to: payload.to,
            area_id:payload.area_id
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchArea() {
      return new Promise((resolve, reject) => {
        axios
          .post(`${this.state.customConfig.baseURL}/web/area/dropdown`, {
            apikey: this.state.customConfig.token,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
